<template>
  <div style="background-color:#000; width: 100%; height: 100vh; border-radius: 5px;">
    <center>
      <title>รหัสผ่าน</title>
      <img src="../assets/image/UI Member PHOTO/Icon Popup-01.png" class="img-fluid" alt="Responsive image" style="width: 50%; padding-top: 20px; padding-bottom: 20px;"/>
      <h1 style="color: #8350ff; font-size: 20px">ระบบรักษาความปลอดภัย</h1>
      <h1 style="color: #8350ff; font-size: 20px">ด้วยรหัส Pin</h1>
    </center>

    <center>
      <CCard style="width: 85%;">
        <CCardBody style="background-color:#e5e5e5;">
          <div style="padding-bottom: 10px;">
            <center>
              <h1 style="font-size: 15px">กรอก รหัส Pin 4 หลัก</h1>
            </center>
            <CInput 
              type="password" 
              v-model="pin" 
              @input="checkPin()" 
              placeholder="pin" 
              aria-label="pin" 
              aria-describedby="basic-addon1"
              style="font-family: Kanit, sans-serif;"
              oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
            ></CInput>
            <div style="display: flex;">
              <p style="color:#FF0000; margin: 0 0 0 10px; padding: 0;">{{warningText.pin}}</p>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </center>

    <div style="display: flex; justify-content: center; margin: 0 0 50px 0;">
      <CButton v-on:click="Cancel()" style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
        <p style="margin: 0 10px;">ยกเลิก</p>
      </CButton>
      <CButton v-on:click="Conferim()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
        <p style="margin: 0 10px;">ตกลง</p>
      </CButton>
    </div>

    <!-- <div style="margin: 100% 0 0 0; color:#000">.</div> -->
  </div>


    

</template>

<script>
import MainChartExample from './charts/MainChartExample'
import WidgetsDropdown from './widgets/WidgetsDropdown'
import WidgetsBrand from './widgets/WidgetsBrand'

import { freeSet } from '@coreui/icons'
import config from '../config'
const axios = require("axios");

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      profile:"",
      pin:"",
      statuslogin: false,
      pageww:"",
      warningText :{
        'pin':''
      }

  
    };
  },
  mounted() {
    this.profile = this.$store.state.profile
    this.branch = this.$store.state.profile.branch
    this.$store.state.showNav = false
    this.pageww = this.$route.query.pageww
  },
  methods: {
    checkPin(){
      if(!this.pinmode){
        this.warningText.pin = ''
        if(this.pin.length == 4){
          let data = {"pin":this.pin,"numberphone": this.profile.numberphone,"branch": parseInt(this.branch) }
          // this.API_Login(data).then((res) => {
          //   this.statuslogin = res.data.comparepin
          //   if(res.data.comparepin){
          //     this.warningText.pin = ''
          //   }
          //   else{
          //     this.warningText.pin = '*รหัสผ่านไม่ถูกต้อง'
          //   }
          // });
        }
        else{

        }
      }
    },
    Conferim(){
      if(this.pin.length == 4){
        let data = {"pin":this.pin,"numberphone": this.profile.numberphone,"branch": parseInt(this.branch) }
        console.log(data)
        this.API_Login(data).then((res) => {
          this.statuslogin = res.data.comparepin
          if(res.data.comparepin){
            this.warningText.pin = ''
            this.$store.state.showNav = true
            if(this.pageww == "setting"){
              this.$router.push('/settingww')
            }
            else if(this.pageww == "member"){
              this.$router.push('/memberww')
            }
            else if(this.pageww == "reward"){
              this.$router.push('/rewardww')
            }
            else if(this.pageww == "new"){
              this.$router.push('/newsww')
            }
            else if(this.pageww == "operation"){
              this.$router.push('/operationww')//
            }
            else if(this.pageww == "status"){
              this.$router.push('/operationww')//
            }
            else{
              this.$router.push('/memberww')
            }
          }
          else{
            this.warningText.pin = '*รหัสผ่านไม่ถูกต้อง'
          }
        });
      }
      else{
        this.warningText.pin = '*กรอก pin ให้ครบ 4 หลัก'
      }
    },
    Cancel(){

    },
    API_Login(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/login", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    }
  },
};
</script>
